import React, { useState, useEffect, useContext } from 'react'
import { RiLockPasswordFill } from "react-icons/ri";
import { AuthContext } from "../Auth"
import { Password } from "../Module/Data"

export default function Changepassword() {

    const { authperson, Logout } = useContext(AuthContext);

    const [ChangePassword, setChangePassword] = useState("");
    const [ConfirmPassword, setConfirmPassword] = useState("");
    const [OldPassword, setOldPassword] = useState("");
    const [passchnagesuccess, setpasschnagesuccess] = useState(false)

    const [btnSave, setbtnSave] = useState(false);

    useEffect(() => {
        if (ChangePassword !== "") {
            if (ChangePassword === ConfirmPassword) {
                if (OldPassword.length > 0) {
                    setbtnSave(true);
                } else {
                    setbtnSave(false);
                }

            } else {
                setbtnSave(false);
            }
        } else {
            setbtnSave(false);
        }

    }, [ChangePassword, ConfirmPassword, OldPassword]);

    const Close = () => {
        setChangePassword("")
        setConfirmPassword("")
        setOldPassword("")
    }

    const SavePassword = () => {
        Password(authperson, ChangePassword, OldPassword, Logout, setpasschnagesuccess);
        setChangePassword("")
        setConfirmPassword("")
        setOldPassword("")
    }
    return (



        <div>
            <button type="button" className="Logout" data-bs-toggle="modal" data-bs-target="#exampleModal">
                <RiLockPasswordFill /> Change Password
            </button>

            <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Change Password </h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="row justify-content-center">
                                <div className="row px-2">
                                    <div className="col-md-6 mt-2"><label htmlFor="" className='text-dark'>Old Password:</label></div>
                                    <div className="col-md-6 mt-2">   <input type="text" value={OldPassword} onChange={e => { setOldPassword(e.target.value) }} placeholder='Old Password' style={{ fontFamily: "fantasy" }} /></div>
                                    <div className="col-md-6 mt-2"><label htmlFor="" className='text-dark'>New Password:</label></div>
                                    <div className="col-md-6 mt-2">   <input type="text" value={ChangePassword} onChange={e => { setChangePassword(e.target.value) }} placeholder='New Password' style={{ fontFamily: "fantasy" }} /></div>
                                    <div className="col-md-6 mt-2"><label htmlFor="" className='text-dark'>Confirm Password:</label></div>
                                    <div className="col-md-6 mt-2">   <input type="text" value={ConfirmPassword} onChange={e => { setConfirmPassword(e.target.value) }} placeholder='Confirm Password' style={{ fontFamily: "fantasy" }} /></div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            {passchnagesuccess && <p className='text-danger'>{passchnagesuccess} </p>}

                            <button type="button" className="btn btn-secondary Close" data-bs-dismiss="modal" onClick={() => Close()}>Close</button>

                            {btnSave &&
                                <button type="button" className=" Logout " onClick={SavePassword}>Save Password</button>
                            }
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}
