import React from 'react'

export default function Spinner() {
    return (
        <div className='d-flex justify-content-center  align-items-center ' style={{ height: "50vh", width: "80vw" }}>
            <div className="spinner-border text-danger " role="status">
                <span className="visually-hidden">Loading...</span>
            </div>
        </div>
    )
}
