import React from 'react'
import { TKMimgpath, MediaType } from '../../Module/Data'
import { GrView } from "react-icons/gr";
import photo from "../../img/photo.png"


export default function PassportPhoto({ e }) {
    return (
        <div>
            <div>
                <div>


                    {MediaType(e.PassportPhoto) === "img" ?
                        <button type="button" className="btn px-0 " data-bs-toggle="modal" data-bs-target={"#C" + e.RecordId} >


                            <img src={photo} alt="" data-bs-toggle="tooltip" data-bs-placement="top" title=" Passport Photo" style={{ height: "25px", width: "25px" }} />
                        </button> :
                        <a href={TKMimgpath(e.AddressProof)} target='_blank'>
                            <button type="button" className="btn px-0 " style={{ height: "20px", width: "20px" }} >
                                <img src={photo} alt="" data-bs-toggle="tooltip" data-bs-placement="top" title=" Passport Photo" style={{ height: "25px", width: "25px" }} />


                            </button></a>}




                    <div className="modal fade" id={"C" + e.RecordId} data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5" id="staticBackdropLabel">  Passport Photo</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <img src={TKMimgpath(e.PassportPhoto)} className="img-thumbnail" alt="No Image"></img>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
