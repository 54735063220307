import React, { createContext, useState } from "react";

const AuthContext = createContext()


const AuthState = (props) => {
    const [pdfLoading, setpdfLoading] = useState("pdf generator")
    const [pdfClose, setpdfClose] = useState(false)
    
    const [authLogin, setauthLogin] = useState(false)
    const [authToken, setauthToken] = useState("")
    const [authperson, setauthperson] = useState("")
    const [refresh, setrefresh] = useState(false)

    const Logout = () => {
        setauthLogin(false)
        setauthperson('')
        localStorage.removeItem("authperson")
        localStorage.removeItem("authLogin")
    }

    return (

        <AuthContext.Provider value={{
            Logout, authLogin, setauthLogin,
            authToken, setauthToken, authperson,
            setauthperson, refresh, setrefresh, pdfLoading, setpdfLoading, pdfClose, setpdfClose
        }}>
            {
                props.children
            }
        </AuthContext.Provider>
    )
}

export { AuthContext, AuthState }