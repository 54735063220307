/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useContext } from 'react';
import Navbar from './Navbar'
import "./TYP.css"
import { TKMdata, TKMdownloadExcel } from "../Module/Data"
import { AuthContext } from "../Auth"
import ImgModal from './Imgmodal';
import IDProof from './TKM/IDProof';
import AddressProof from './TKM/AddressProof';
import MemberImage from './MemberImage';
import Status from './Status';
import Spinner from '../componet/Spinner';
import PaymentScreentShot from './TKM/PaymentScreentShot';
import TKMStatus from './TKM/TKMStatus';
import PassportPhoto from './TKM/PassportPhoto';
import { FaDownload } from "react-icons/fa";
import { GeneratePdfTKM } from '../Module/GeneratePdf';


export default function TKM() {

    const [pdfLoading, setpdfLoading] = useState("")


    const { authperson } = useContext(AuthContext)

    const [rifresh, setrifresh] = useState(false)
    const [userdata, setuserdata] = useState([])
    const [Loading, setLoading] = useState(false)

    useEffect(() => {
        TKMdata(authperson, setuserdata, setLoading)
    }, [rifresh, authperson])


    const remaintime = (date) => {
        const currentdate = new Date();
        const Birthdate = new Date(date);

        const remainingMilliseconds = currentdate - Birthdate;

        const millisecondsInYear = 365 * 24 * 60 * 60 * 1000;
        const years = Math.floor(remainingMilliseconds / millisecondsInYear);
        return years;
    };

    const formatDateToDdmmyy = (inputDate) => {

        if (inputDate) {
            const date = new Date(inputDate);

            const year = date.getFullYear().toString().slice(-2); // Get the last two digits of the year
            const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Add 1 to month (0-based index) and pad with '0' if necessary
            const day = date.getDate().toString().padStart(2, '0'); // Pad day with '0' if necessary

            return `${day}/${month}/${year}`;

        }
        return null
    }

    return (
        <div className='' >
            <Navbar />
            <div class="modal fade" id="Download" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">

                        <div class="modal-body d-flex justify-content-center">
                            {pdfLoading === "start" && <div class="spinner-border text-danger" role="status">
                                <span class="visually-hidden">Loading...</span>
                            </div>}
                            {pdfLoading === "complete" &&
                                <div>
                                    <h3>Pdf Downloaded Successfully</h3>
                                    <div class="modal-footer border-0">
                                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                    </div>
                                </div>
                            }
                            {pdfLoading === "error" &&
                                <div>
                                    <h3>Error During Generating Pdf</h3>

                                    <div class="modal-footer border-0">
                                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                    </div>
                                </div>
                            }
                        </div>

                    </div>
                </div>
            </div>

            <div className='d-flex justify-content-center'>
                <div className='overflow-auto' style={{ maxWidth: "1337" }}>
                    <div className=''>
                        <div className='d-flex justify-content-start mt-5 '>
                            <button type="button" className="btn btn-danger" hidden={userdata.length < 1} onClick={() => TKMdownloadExcel(userdata)}>Export  Excel <FaDownload className='fs-6' /></button>
                        </div>
                    </div>

                    <div className=" mx-auto bg-white overflow-auto rounded  mt-2 " style={{ height: "70vh", maxWidth: "1337" }}>
                        <div className=" row text-white m-0 p-0 tkmh " style={{ display: 'flex', flexWrap: 'nowrap', backgroundColor: '#382F86', position: "sticky", top: "0px" }}>
                            <div className="hadingno "  >
                                Sr No
                            </div>
                            <div className="hading "  >
                                Registration Date
                            </div>
                            <div className="hading " >
                                Full Name
                            </div>

                            <div className="hading "  >
                                Date Of Birth
                            </div>
                            <div className="hading "  >
                                Mobile No
                            </div>
                            <div className="hading " style={{ width: "300px" }} >
                                Address
                            </div>

                            <div className="hading "  >
                                In charge / Coordinator
                            </div>



                            <div className="hading " style={{ width: "200px" }}>
                                Document
                            </div>

                            <div className="hading"  >
                                Status
                            </div>
                        </div>

                        {
                            userdata.length > 0 ? userdata?.map((e, i) =>
                                <div className="row m-0 p-0 tkmh" key={e.RecordId} style={{ display: 'flex', flexWrap: 'nowrap', }}>
                                    <div className="hadingdatano  "  >
                                        {i + 1}
                                    </div>
                                    <div className="hadingdata text-break " >
                                        {formatDateToDdmmyy(e.RegistrationDateTime)}
                                    </div>
                                    <div className="hadingdata text-break " style={{ textAlign: "left" }}>
                                        {e.FirstName + " " + e.MiddleName + " " + e.Surname}
                                    </div>
                                    <div className="hadingdata text-break "  >
                                        {formatDateToDdmmyy(e.DateOfBirth)}
                                    </div>
                                    <div className="hadingdata text-break "  >
                                        {e.Mobile}
                                    </div>

                                    <div className="hadingdata text-break " style={{ width: "300px", textAlign: "left" }} >
                                        {e.HouseNo + ", " + e.SocietyName + ", " + e.HomeLandmark + ", " + e.HomeArea + ", " + e.HomeCity + ", " + e.HomePincode + "."}
                                    </div>

                                    <div className="hadingdata text-break " >
                                        {e.Incharge} / {e.Coordinator}
                                    </div>


                                    <div className="hadingdata text-break " style={{ width: "200px" }}>
                                        <div className='d-flex justify-content-center'>
                                            <PassportPhoto e={e} />
                                            <AddressProof e={e} />

                                            <IDProof e={e} />
                                            <PaymentScreentShot e={e} />


                                            <button type="button" class="btn p-0 m-0" data-bs-toggle="modal" data-bs-target="#Download">
                                                <FaDownload onClick={() => GeneratePdfTKM(e, setpdfLoading)} style={{ height: "25px", width: "25px", color: "#CD3839" }} />
                                            </button>
                                        </div>

                                    </div>
                                    <div className="hadingdata text-break "  >
                                        <TKMStatus e={e} setrifresh={setrifresh} />
                                    </div>

                                </div>
                            ) : Loading && <Spinner />
                        }
                    </div>

                </div>
            </div>

        </div>

    )
}
