import React from 'react'
import { GrView } from "react-icons/gr";
import { MediaType, TKMimgpath } from '../../Module/Data'
import id from "../../img/driver-license.png"

export default function IDProof({ e }) {
    return (
        <div>
            <div>


                {MediaType(e.IdProof) === "img" ?
                    <button type="button" className="btn px-0 " data-bs-toggle="modal" data-bs-target={"#A" + e.RecordId}>
                        <img src={id} alt="" data-bs-toggle="tooltip" data-bs-placement="top" title="ID Proof" style={{ height: "25px", width: "25px" }} />

                    </button> :
                    <a href={TKMimgpath(e.AddressProof)} target='_blank'>
                        <button type="button" className="btn px-0 " >
                            <img src={id} alt="" data-bs-toggle="tooltip" data-bs-placement="top" title="ID Proof" style={{ height: "25px", width: "25px" }} />

                        </button></a>}


                <div className="modal fade" id={"A" + e.RecordId} data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h1 className="modal-title fs-5" id="staticBackdropLabel">ID Proof</h1>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">

                                <img src={TKMimgpath(e.IdProof)} className="img-thumbnail" alt="No Image"></img>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
