import React from 'react'
import { MediaType, TKMimgpath } from '../../Module/Data'
import location from "../../img/location.png"


export default function AddressProof({ e }) {
    return (
        <div>
            {MediaType(e.AddressProof) === "img" ?
                <button type="button" className="btn px-0 " data-bs-toggle="modal" data-bs-target={"#D" + e.RecordId} >
                    <img data-bs-toggle="tooltip" data-bs-placement="top" title="Address proof" src={location} alt="" style={{ height: "25px", width: "25px" }} />
                </button> :
                <a href={TKMimgpath(e.AddressProof)} target='_blank'>
                    <button type="button" className="btn px-0 " >
                        <img src={location} alt="" data-bs-toggle="tooltip" data-bs-placement="top" title="Address proof" style={{ height: "25px", width: "25px" }} />
                    </button></a>}


            <div className="modal fade" id={"D" + e.RecordId} data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="staticBackdropLabel"> Address Proof</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <img src={TKMimgpath(e.AddressProof)} className="img-thumbnail" alt="No Image"></img>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}
