import React, { useState, useContext } from 'react'
import { CiEdit } from "react-icons/ci";
import { AuthContext } from "../Auth"
import { Statusdata } from "../Module/Data"



export default function Status({ e, setrifresh }) {
    const [Status, setStatus] = useState("")

    const [mid, setmid] = useState(e.MemberId)


    const { authperson } = useContext(AuthContext);


    const SaveStatus = async () => {
        const res = await Statusdata(authperson, Status, mid);
        if (res.status === 1) {
            setrifresh(prev => !prev)
        }

        setStatus("")
    }


    return (
        <div>
            <button type="button" className="btn px-0 " data-bs-toggle="modal" data-bs-target={"#e" + e.MemberId}>
                {e.Status} <CiEdit className='fs-5' style={{ color: "#CD3839" }} />
            </button>

            <div className="modal fade" id={"e" + e.MemberId} tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Edit Status</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <select className="form-select" aria-label="Default select example" value={Status} onChange={e => setStatus(e.target.value)}>
                                <option selected>select Status</option>
                                <option value="Verified">Check & Verified</option>
                                <option value="Approved">Approved</option>
                            </select>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn px-0 btn-secondary Close" data-bs-dismiss="modal">Close</button>
                            <button type="button" className="px-2 py-2" data-bs-dismiss="modal" hidden={Status === ""} onClick={() => SaveStatus()} style={{ border: "none", backgroundColor: "#CD3839", color: "#fff", borderRadius: "10px" }}>Save Status</button>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    )
}
