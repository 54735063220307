/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import { imgpath, MediaType } from '../Module/Data'
import { GrView } from "react-icons/gr";
import photo from "../img/photo.png"


export default function MemberImage({ e }) {
    return (
        <div>
            <div>
                <div>


                    {MediaType(e.PassportPhoto) === "img" ?
                        <button type="button" className="btn px-0 " data-bs-toggle="modal" data-bs-target={"#C" + e.MemberId} >


                            <img src={photo} alt="" data-bs-toggle="tooltip" data-bs-placement="top" title=" Passport Photo" style={{ height: "25px", width: "25px" }} />
                        </button> :
                        <a href={imgpath(e.PassportPhoto)} target='_blank'>
                            <button type="button" className="btn px-0 ">
                                <img src={photo} alt="" data-bs-toggle="tooltip" data-bs-placement="top" title=" Passport Photo" style={{ height: "25px", width: "25px" }} />
                            </button></a>}



                    <div className="modal fade" id={"C" + e.MemberId} data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5" id="staticBackdropLabel">  Passport Photo</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <img src={imgpath(e.PassportPhoto)} className="img-thumbnail" alt="No Image"></img>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
