import React, { useContext } from 'react'
import logo from "../img/TYP-red-2.png"
import { IoIosLogOut } from "react-icons/io";
import { RiLockPasswordFill } from "react-icons/ri";
import { AuthContext } from "../Auth"
import Changepassword from './Changepassword';
import { NavLink } from 'react-router-dom';


export default function Navbar() {


  const { Logout } = useContext(AuthContext)

  const NavStyle = ({ isActive }) => {
    return {
      borderBottom: isActive ? "2px solid #EE3A3B" : ''
    }
  }

  return (
    <nav className="navbar navbar-expand-lg navbar-light vw-100 shadow">
      <div className="container-fluid d-flex justify-content-between">
        <div className='d-flex justify-content-between align-items-center'>
          <a href="#" className='d-flex align-items-center justify-content-center me-5' style={{ textDecoration: "none" }}>
            <img src={logo} alt="" style={{ width: "80px", height: "80px" }} />
            <span className="fs-4 " style={{ color: "#CD3839" }}><b>Terapanth Yuvak Parishad Ahmedabad</b> </span>
          </a>

          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <NavLink className="nav" style={NavStyle} aria-current="page" to="/UseMaster"><b>TYP</b></NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav" style={NavStyle} to="/TKM" tabIndex="-1" aria-disabled="true"><b>TKM</b></NavLink>
            </li>

          </ul>
        </div>
        <div className='d-flex'>
          <div className='Logout py-2' onClick={Logout} style={{ border: "none", backgroundColor: "#CD3839", color: "#fff", borderRadius: "10px" }}>    <IoIosLogOut className=' pt-1 fs-5' /> Logout</div>
          <div className='Logout py-2' style={{ border: "none", backgroundColor: "#CD3839", color: "#fff", borderRadius: "10px" }}>   <Changepassword /> </div>
        </div>

      </div>
    </nav>
  )
}
