import axios from "axios";
import React, { useState, useContext } from "react";
import * as XLSX from 'xlsx';

// export const serverip = "http://192.168.1.80:810"
// export const serverip = "http://68.178.169.094:8010"

export const imgpath = (path) => {
    return `https://admin.typahmedabad.org/images/members/${path}`
}

export const TKMimgpath = (path) => {
    return `https://admin.typahmedabad.org/images/kishors/${path}`
}


export const MediaType = (path) => {
    if (path) {
        const fileExtension = path.substring(path.lastIndexOf('.') + 1).toLowerCase();
        if (['jpeg', 'jpg', 'png'].includes(fileExtension)) {
            return "img"
        } else {
            return 'document'
        }
    } else {
        return 'document'
    }
}

export const data = async (authperson, setuserdata, setLoading) => {
    try {
        setLoading(true)
        const response = await axios.post(
            `https://www.typahmedabad.org/getmember.php`,
            {
                UserLoginid: authperson.UserName, UserPassword: authperson.UserPassword
            },
        );
        console.log(response.data.data.map(e => ({ PassportPhoto: e.PassportPhoto, AddressProof: e.AddressProof, IdProof: e.IdProof, PaymentPhoto: e.PaymentPhoto })));
        setuserdata(response.data.data)
        setLoading(false)
    } catch (error) {
        setLoading(false)

        console.error("Error fetching customer data:", error);
    }
};

export const TKMdata = async (authperson, setuserdata, setLoading) => {
    try {
        setLoading(true)
        const response = await axios.post(
            `https://www.typahmedabad.org/getkishor.php`,
            {
                UserLoginid: authperson.UserName, UserPassword: authperson.UserPassword
            },
        );
        if (response.data.data) {
            setuserdata(response.data.data)
        }
        setLoading(false)

    } catch (error) {
        setLoading(false)

        console.error("Error fetching customer data:", error);
    }
};

export const Password = async (authperson, ChangePassword, OldPassword, Logout, setpasschnagesuccess) => {
    try {
        const response = await axios.post(
            `https://www.typahmedabad.org/changepassword.php`,
            {
                UserLoginid: authperson.UserName,
                UserPassword: OldPassword,
                NewPassword: ChangePassword
            }
        );
        if (response.data.status === 1) {
            setpasschnagesuccess("Password Changed Successfully")
            setTimeout(() => {
                setpasschnagesuccess(false)
                Logout()
                window.location.reload()
            }, 2000);
        } else if (response.data.status === 0) {
            setpasschnagesuccess("Old Password does't match or new password is already same to saved password")
            setTimeout(() => {
                setpasschnagesuccess(false)
            }, 2000);

        }
    } catch (error) {
        setpasschnagesuccess("Error changing password")
        setTimeout(() => {
            setpasschnagesuccess(false)
        }, 2000);
        console.error("Error changing password:", error);
    }
};

export const Statusdata = async (authperson, Status, mid) => {


    try {
        const response = await axios.post(
            `https://www.typahmedabad.org/changestatus.php`,
            {
                UserLoginid: authperson.UserName,
                UserPassword: authperson.UserPassword,
                MId: mid,
                Status: Status
            }
        );
        return response.data

    } catch (error) {
        console.error("Error fetching customer data:", error);
        // Optionally handle error, display a message to the user, etc.
    }
};

export const TKMStatusdata = async (authperson, Status, mid) => {
    try {
        const response = await axios.post(
            `https://www.typahmedabad.org/kishorstatus.php`,
            {
                UserLoginid: authperson.UserName,
                UserPassword: authperson.UserPassword,
                MId: mid,
                Status: Status
            }
        );


        return response.data
    } catch (error) {
        console.error("Error fetching customer data:", error);
    }
};

export const downloadExcel = (data) => {

    const header = []
    Object.keys(data[0]).forEach(element => {
        if (!["PassportPhoto", "IdProof", "AddressProof", "PaymentPhoto"].includes(element)) {
            header.push(element)
        }
    });
    const xldata = [header]
    for (let index = 0; index < data.length; index++) {
        const element = data[index];
        const data2 = []
        Object.values(element).forEach((value, i) => {
            if (![6, 11, 23, 34].includes(i)) {
                data2.push(value)
            }
        });
        xldata.push(data2)
    }

    // Create a new workbook
    const wb = XLSX.utils.book_new();
    // Convert the data array to a worksheet
    const ws = XLSX.utils.aoa_to_sheet(xldata);
    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    // Convert the workbook to a binary XLS file
    const wbout = XLSX.write(wb, { type: "binary", bookType: "xls" });

    // Function to convert the binary string to a Blob
    const s2ab = (s) => {
        const buf = new ArrayBuffer(s.length);
        const view = new Uint8Array(buf);
        for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
        return buf;
    };

    // Convert the binary string to a Blob
    const blob = new Blob([s2ab(wbout)], { type: "application/octet-stream" });
    // Create a temporary URL for the Blob
    const url = URL.createObjectURL(blob);
    // Create a link element
    const a = document.createElement("a");
    // Set the href attribute to the temporary URL
    a.href = url;
    // Set the download attribute to specify the file name
    a.download = "example.xls";
    // Append the link element to the document body
    document.body.appendChild(a);
    // Click the link to trigger the download
    a.click();
    // Remove the link element from the document body
    document.body.removeChild(a);
    // Revoke the temporary URL to release memory
    URL.revokeObjectURL(url);
};


export const TKMdownloadExcel = (data) => {
    const header = []
    Object.keys(data[0]).forEach(element => {
        if (!["PassportPhoto", "IdProof", "AddressProof", "PaymentPhoto"].includes(element)) {
            header.push(element)
        }
    });

    const xldata = [header]

    for (let index = 0; index < data.length; index++) {
        const element = data[index];
        const data2 = []
        Object.values(element).forEach((value, i) => {
            if (![6, 8, 19, 20].includes(i)) {
                data2.push(value)
            }
        });
        xldata.push(data2)
    }

    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.aoa_to_sheet(xldata);
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    const wbout = XLSX.write(wb, { type: "binary", bookType: "xls" });

    const s2ab = (s) => {
        const buf = new ArrayBuffer(s.length);
        const view = new Uint8Array(buf);
        for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
        return buf;
    };

    // Convert the binary string to a Blob
    const blob = new Blob([s2ab(wbout)], { type: "application/octet-stream" });
    // Create a temporary URL for the Blob
    const url = URL.createObjectURL(blob);
    // Create a link element
    const a = document.createElement("a");
    // Set the href attribute to the temporary URL
    a.href = url;
    // Set the download attribute to specify the file name
    a.download = "example.xls";
    // Append the link element to the document body
    document.body.appendChild(a);
    // Click the link to trigger the download
    a.click();
    // Remove the link element from the document body
    document.body.removeChild(a);
    // Revoke the temporary URL to release memory
    URL.revokeObjectURL(url);
};


export const DynamicSize = (ImgDims) => {
    const aspectratio = ImgDims.width / ImgDims.height
    const maxHeight = 155
    const maxWidth = 116
    let width = ImgDims.width;
    let height = ImgDims.height;

    if (width > maxWidth || height > maxHeight) {
        if (width / height > aspectratio) {
            width = Math.min(width, maxWidth);
            height = width / aspectratio;
        } else {
            height = Math.min(height, maxHeight);
            width = height * aspectratio;
        }
    }
    return [height, width]

}

export const DynamicSizeTYP = (ImgDims, maxHeight, maxWidth) => {
    const aspectratio = ImgDims.width / ImgDims.height
    let width = ImgDims.width;
    let height = ImgDims.height;

    if (width > maxWidth || height > maxHeight) {
        if (width / height > aspectratio) {
            width = Math.min(width, maxWidth);
            height = width / aspectratio;
        } else {
            height = Math.min(height, maxHeight);
            width = height * aspectratio;
        }
    }
    return [height, width]

}