/* eslint-disable react-hooks/exhaustive-deps */
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import React, { useEffect, useContext, lazy, Suspense } from "react";
import { AuthContext } from "./Auth"
import {
  Routes,
  Route,
} from "react-router-dom"
import { useNavigate } from "react-router-dom";
import TKM from './componet/TKM.jsx';
const Loging = lazy(() => import('./componet/Loging.js'))
const Farmer = lazy(() => import('./componet/TYP.js'))

function App() {
  const navigate = useNavigate()
  const { authLogin, setauthLogin, setauthperson } = useContext(AuthContext)

  useEffect(() => {

    if (authLogin === false) {
      const Authperson = localStorage.getItem('authperson')
      const Authlogin = localStorage.getItem('authLogin')
      if (Authperson && Authlogin) {
        setauthLogin(Authlogin === "true")
        setauthperson(JSON.parse(Authperson))
      } else {
        navigate("/")
      }

    }
  }, [authLogin])

  return (
    <>

      <Suspense fallback={<p>Loading....</p>}>
        <Routes>
          <Route path="/" element={<Loging />} />
          <Route path="/UseMaster" element={authLogin === true ? <Farmer /> : <Loging />} />
          <Route path="/TKM" element={<TKM />} />

        </Routes>
      </Suspense>
    </>
  );
}

export default App;
