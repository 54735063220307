/* eslint-disable no-unused-vars */
import { PDFDocument, StandardFonts, rgb, degrees, grayscale, PageSizes } from 'pdf-lib';


import TKMForm from "./TKMFORM.pdf";
import TYPForm from "./TYPForm.pdf";
import { format } from "date-fns";
import imgurl from './1_passport.jpeg'
import imgurlTYP from './1_passport.jpeg'
import { DynamicSize, DynamicSizeTYP, TKMimgpath, imgpath } from './Data';




export const GeneratePdfTKM = async (e, setpdfLoading) => {



    try {
        setpdfLoading("start")
        const birthdate = format(new Date(e.DateOfBirth), "dd/MMM/yyyy")
        const formateddate = birthdate.split('/')
        const today = new Date()
        const currentyear = today.getFullYear()
        const age = currentyear - formateddate[2]

        const RegistrationDateTime = format(new Date(e.RegistrationDateTime), "dd/MMM/yyyy")

        // const ImageUrl = imgurl
        const ImageUrl = TKMimgpath(e.PassportPhoto)

        const [ImageBytes, existingPdfBytes] = await Promise.all([
            fetch(ImageUrl).then((res) => res.arrayBuffer()),
            fetch(TKMForm).then(res => res.arrayBuffer())

        ])

        const pdfDoc = await PDFDocument.load(existingPdfBytes)

        let Image

        if (e.PassportPhoto.split('.')[1] === 'png') {

            Image = await pdfDoc.embedPng(ImageBytes)
        } else {

            Image = await pdfDoc.embedJpg(ImageBytes)
        }

        const ImgDims = Image.scale(0.1)

        const timesRomanFont = await pdfDoc.embedFont(StandardFonts.TimesRomanBoldItalic)

        const pages = pdfDoc.getPages()
        const page = pages[0]
        const { width, height } = page.getSize()

        page.drawImage(Image, {
            x: 438,
            y: 615,
            width: DynamicSize(ImgDims)[1],
            height: DynamicSize(ImgDims)[0],
        })

        page.drawText(`${e.FirstName}`, {
            x: 190,
            y: height / 2 + 227,
            size: 12,
            font: timesRomanFont.Courier,
            color: rgb(0, 0, 0),
        });

        page.drawText(`${e.MiddleName}`, {
            x: 190,
            y: height / 2 + 205,
            size: 12,
            font: timesRomanFont.Courier,
            color: rgb(0, 0, 0),
            textAlign: 'right',
        });
        page.drawText(`${e.Surname}`, {
            x: 190,
            y: height / 2 + 181,
            size: 12,
            font: timesRomanFont.Courier,
            color: rgb(0, 0, 0),
            textAlign: 'right',
        });

        page.drawText(`${formateddate[0]}`, {
            x: 192,
            y: height / 2 + 155,
            size: 12,
            font: timesRomanFont.Courier,
            color: rgb(0, 0, 0),
            textAlign: 'right',
        });

        page.drawText(`${formateddate[1]}`, {
            x: 235,
            y: height / 2 + 155,
            size: 12,
            font: timesRomanFont.Courier,
            color: rgb(0, 0, 0),
            textAlign: 'right',
        });

        page.drawText(`${formateddate[2]}`, {
            x: 272,
            y: height / 2 + 155,
            size: 12,
            font: timesRomanFont.Courier,
            color: rgb(0, 0, 0),
            textAlign: 'right',
        });

        page.drawText(`${age}`, {
            x: 190,
            y: height / 2 + 130,
            size: 12,
            font: timesRomanFont.Courier,
            color: rgb(0, 0, 0),
            textAlign: 'right',
        });

        page.drawText(`${e.NativePlace}`, {
            x: 190,
            y: height / 2 + 107,

            size: 12,
            font: timesRomanFont.Courier,
            color: rgb(0, 0, 0),
            textAlign: 'right',
        });

        page.drawText(`${e.HouseNo}`, {
            x: 190,
            y: height / 2 + 41,
            size: 12,
            font: timesRomanFont.Courier,
            color: rgb(0, 0, 0),
            textAlign: 'right',
        });

        page.drawText(`${e.SocietyName}`, {
            x: 190,
            y: height / 2 + 14,
            size: 12,
            font: timesRomanFont.Courier,
            color: rgb(0, 0, 0),
            textAlign: 'right',
        });
        page.drawText(`${e.HomeLandmark}`, {
            x: 190,
            y: height / 2 - 9,
            size: 12,
            font: timesRomanFont.Courier,
            color: rgb(0, 0, 0),
            textAlign: 'right',
        });

        page.drawText(`${e.RecordId}`, {
            x: width - 200,
            y: height / 2 + 75,
            size: 12,
            font: timesRomanFont.Courier,
            color: rgb(0, 0, 0),
            textAlign: 'right',
        });

        page.drawText(`${e.HomeArea}`, {
            x: width - 190,
            y: height / 2 + 43,
            size: 12,
            font: timesRomanFont.Courier,
            color: rgb(0, 0, 0),
            textAlign: 'right',
        });

        page.drawText(`${e.HomeCity}`, {
            x: width - 190,
            y: height / 2 + 19,
            size: 12,
            font: timesRomanFont.Courier,
            color: rgb(0, 0, 0),
            textAlign: 'right',
        });


        page.drawText(`${e.HomePincode}`, {
            x: width - 180,
            y: height / 2 - 5,
            size: 12,
            font: timesRomanFont.Courier,
            color: rgb(0, 0, 0),
            textAlign: 'right',
        });


        page.drawText(`${e.Mobile}`, {
            x: 190,
            y: height / 2 - 73,
            size: 12,
            font: timesRomanFont.Courier,
            color: rgb(0, 0, 0),
            textAlign: 'right',
        });

        page.drawText(`${e.Email}`, {
            x: 190,
            y: height / 2 - 97,
            size: 12,
            font: timesRomanFont.Courier,
            color: rgb(0, 0, 0),
            textAlign: 'right',
        });

        page.drawText(`${e.Incharge}`, {
            x: 190,
            y: height / 2 - 179,
            size: 12,
            font: timesRomanFont.Courier,
            color: rgb(0, 0, 0),
            textAlign: 'right',
        });

        page.drawText(`${e.Coordinator}`, {
            x: 190,
            y: height / 2 - 200,
            size: 12,
            font: timesRomanFont.Courier,
            color: rgb(0, 0, 0),
            textAlign: 'right',
        });

        page.drawText(`${RegistrationDateTime}`, {
            x: 190,
            y: height / 2 - 226,
            size: 12,
            font: timesRomanFont.Courier,
            color: rgb(0, 0, 0),
            textAlign: 'right',
        });

        const pdfBytes = await pdfDoc.save();

        const blob = new Blob([pdfBytes], { type: 'application/pdf' });

        const pdfUrl = URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = pdfUrl;

        link.download = 'downloaded_pdf.pdf';

        document.body.appendChild(link);

        link.click();

        window.URL.revokeObjectURL(pdfUrl);
        setpdfLoading("complete")


    } catch (error) {
        setpdfLoading("error")

    }


}



export const GeneratePdfTYP = async (e, setpdfLoading) => {
    try {
        setpdfLoading("start")

        const birthdate = format(new Date(e.DateOfBirth), "dd/MMM/yyyy")
        const MarriageDate = e.MarriageDate ? format(new Date(e.MarriageDate), "dd/MMM/yyyy") : null
        const SpouseDateofBirth = e.SpouseDateofBirth ? format(new Date(e.SpouseDateofBirth), "dd/MMM/yyyy") : null

        const ImageUrlTYP1 = imgpath(e.PassportPhoto)
        const ImageUrlTYP2 = imgpath(e.AddressProof)
        const ImageUrlTYP3 = imgpath(e.IdProof)
        const ImageUrlTYP4 = imgpath(e.PaymentPhoto)

        const [ImageBytes1, ImageBytes2, ImageBytes3, ImageBytes4, existingPdfBytes] = await Promise.all([
            fetch(ImageUrlTYP1).then((res) => res.arrayBuffer()),
            fetch(ImageUrlTYP2).then((res) => res.arrayBuffer()),
            fetch(ImageUrlTYP3).then((res) => res.arrayBuffer()),
            fetch(ImageUrlTYP4).then((res) => res.arrayBuffer()),
            fetch(TYPForm).then(res => res.arrayBuffer())
        ])

        const pdfDoc = await PDFDocument.load(existingPdfBytes)

        const PDFdata = async (fileext, Bytes) => {
            let Image
            if (fileext === 'png') {
                Image = await pdfDoc.embedPng(Bytes)
            } else if (['jpeg', 'jpg'].includes(fileext)) {
                Image = await pdfDoc.embedJpg(Bytes)
            } else if (fileext === 'pdf') {
                Image = await PDFDocument.load(Bytes);
            }
            return Image
        }

        const [Image1, Image2, Image3, Image4] = await Promise.all([
            PDFdata(FileExt(e.PassportPhoto), ImageBytes1),
            PDFdata(FileExt(e.AddressProof), ImageBytes2),
            PDFdata(FileExt(e.IdProof), ImageBytes3),
            PDFdata(FileExt(e.PaymentPhoto), ImageBytes4)
        ])

        const timesRomanFont = await pdfDoc.embedFont(StandardFonts.TimesRomanBoldItalic)
        const pages = pdfDoc.getPages()
        const page = pages[0]

        if (['jpeg', 'jpg', 'png'].includes(FileExt(e.AddressProof))) {
            const page1 = pdfDoc.addPage()
            const ImgDims2 = Image2.scale(0.2)
            page1.drawImage(Image2, {
                x: 10,
                y: 10,
                width: DynamicSizeTYP(ImgDims2, 800, 512)[1],
                height: DynamicSizeTYP(ImgDims2, 800, 512)[0],
            })
        } else if (FileExt(e.AddressProof) === "pdf") {
            const [pdf2] = await pdfDoc.copyPages(Image2, [0])
            pdfDoc.addPage(pdf2)
        }

        if (['jpeg', 'jpg', 'png'].includes(FileExt(e.IdProof))) {
            const page2 = pdfDoc.addPage()
            const ImgDims3 = Image3.scale(0.2)
            page2.drawImage(Image3, {
                x: 10,
                y: 10,
                width: DynamicSizeTYP(ImgDims3, 800, 512)[1],
                height: DynamicSizeTYP(ImgDims3, 800, 512)[0],
            })
        } else if (FileExt(e.IdProof) === "pdf") {
            const [pdf3] = await pdfDoc.copyPages(Image3, [0])
            pdfDoc.addPage(pdf3)
        }

        if (['jpeg', 'jpg', 'png'].includes(FileExt(e.PaymentPhoto))) {
            const page3 = pdfDoc.addPage()
            const ImgDims4 = Image4.scale(0.2)
            page3.drawImage(Image4, {
                x: 10,
                y: 10,
                width: DynamicSizeTYP(ImgDims4, 800, 512)[1],
                height: DynamicSizeTYP(ImgDims4, 800, 512)[0],
            })
        } else if (FileExt(e.PaymentPhoto) === "pdf") {
            const [pdf4] = await pdfDoc.copyPages(Image4, [0])
            pdfDoc.addPage(pdf4)
        }

        const ImgDims1 = Image1.scale(0.1)

        const { width, height } = page.getSize()

        page.drawImage(Image1, {
            x: 453,
            y: 626,
            width: DynamicSizeTYP(ImgDims1, 120, 80)[1],
            height: DynamicSizeTYP(ImgDims1, 120, 80)[0],
        })

        page.drawText('Ahmedabad', {
            x: 70,
            y: height / 2 + 192,
            size: 12,
            font: timesRomanFont.Courier,
            color: rgb(0, 0, 0),
        });

        page.drawText(e.RegistrationDateTime.split(' ')[0], {
            x: 220,
            y: height / 2 + 192,
            size: 12,
            font: timesRomanFont.Courier,
            color: rgb(0, 0, 0),
        });

        page.drawText(e.FirstName, {
            x: 390,
            y: height / 2 + 192,
            size: 12,
            font: timesRomanFont.Courier,
            color: rgb(0, 0, 0),
        });

        page.drawText(`${e.FirstName} ${e.MiddleName} ${e.Surname}`, {
            x: 80,
            y: height / 2 + 134,
            size: 12,
            font: timesRomanFont.Courier,
            color: rgb(0, 0, 0),
        });

        page.drawText(`${birthdate}`, {
            x: 110,
            y: height / 2 + 100,
            size: 12,
            font: timesRomanFont.Courier,
            color: rgb(0, 0, 0),
            textAlign: 'right',
        });

        page.drawText(`${e.NativePlace}`, {
            x: width - 188,
            y: height / 2 + 100,
            size: 12,
            font: timesRomanFont.Courier,
            color: rgb(0, 0, 0),
            textAlign: 'right',
        });

        page.drawText(`${MarriageDate || '-'}`, {
            x: 115,
            y: height / 2 + 78,
            size: 12,
            font: timesRomanFont.Courier,
            color: rgb(0, 0, 0),
            textAlign: 'right',
        });

        page.drawText(`${e.BloodGroup}`, {
            x: width - 188,
            y: height / 2 + 78,
            size: 12,
            font: timesRomanFont.Courier,
            color: rgb(0, 0, 0),
            textAlign: 'right',
        });

        page.drawText(`${e.HouseNo}, ${e.SocietyName}, `, {
            x: 145,
            y: height / 2 + 55,
            size: 12,
            font: timesRomanFont.Courier,
            color: rgb(0, 0, 0),
        });


        page.drawText(`${e.HomeLandmark}, ${e.HomeArea}, ${e.HomeCity}, ${e.HomePincode}`, {
            x: 45,
            y: height / 2 + 33,
            size: 12,
            font: timesRomanFont.Courier,
            color: rgb(0, 0, 0),
        });

        page.drawText(`${e.OfficeNo && (e.OfficeNo + ",")} ${e.OfficeComplexName && (e.OfficeComplexName + ",")} `, {
            x: 145,
            y: height / 2 + 11,
            size: 12,
            font: timesRomanFont.Courier,
            color: rgb(0, 0, 0),
        });

        page.drawText(`${e.OfficeLandmark && (e.OfficeLandmark + ",")} ${e.OfficeArea && (e.OfficeArea + ",")} ${e.OfficeCity && (e.OfficeCity + ",")} ${e.OfficePincode}`, {
            x: 45,
            y: height / 2 + -10,
            size: 12,
            font: timesRomanFont.Courier,
            color: rgb(0, 0, 0),
        });

        page.drawText(`${e.MemberProfession}`, {
            x: width - 210,
            y: height / 2 + -33,
            size: 12,
            font: timesRomanFont.Courier,
            color: rgb(0, 0, 0),
        });

        page.drawText(`${e.Mobile}`, {
            x: 80,
            y: height / 2 + -55,
            size: 12,
            font: timesRomanFont.Courier,
            color: rgb(0, 0, 0),
        });

        page.drawText(`${e.PhoneR}`, {
            x: 280,
            y: height / 2 + -55,
            size: 12,
            font: timesRomanFont.Courier,
            color: rgb(0, 0, 0),
        });

        page.drawText(`${e.PhoneO}`, {
            x: width - 128,
            y: height / 2 + -55,
            size: 12,
            font: timesRomanFont.Courier,
            color: rgb(0, 0, 0),
            textAlign: 'right',
        });

        page.drawText(`${e.Email}`, {
            x: 70,
            y: height / 2 + -76,
            size: 12,
            font: timesRomanFont.Courier,
            color: rgb(0, 0, 0),
        });

        page.drawText(`${e.IntroducedBy}`, {
            x: width - 200,
            y: height / 2 + -76,
            size: 12,
            font: timesRomanFont.Courier,
            color: rgb(0, 0, 0),
            textAlign: 'right',
        });

        page.drawText(`${e.Qualification}`, {
            x: 110,
            y: height / 2 + -99,
            size: 12,
            font: timesRomanFont.Courier,
            color: rgb(0, 0, 0),
        });

        page.drawText(`${e.SpouceName ? e.SpouceName : '-'}`, {
            x: 111,
            y: height / 2 + -120,
            size: 12,
            font: timesRomanFont.Courier,
            color: rgb(0, 0, 0),
        });

        page.drawText(`${SpouseDateofBirth || '-'}`, {
            x: width - 170,
            y: height / 2 + -120,
            size: 12,
            font: timesRomanFont.Courier,
            color: rgb(0, 0, 0),
            textAlign: 'right',
        });

        page.drawText(`${e.FamilyDetail ? e.FamilyDetail : '-'}`, {
            x: 110,
            y: height / 2 + -143,
            size: 12,
            font: timesRomanFont.Courier,
            color: rgb(0, 0, 0),
        });

        const pdfBytes = await pdfDoc.save();

        const blob = new Blob([pdfBytes], { type: 'application/pdf' });

        const pdfUrl = URL.createObjectURL(blob);

        // window.open(pdfUrl, '_blank')
        const link = document.createElement('a');
        link.href = pdfUrl;

        link.download = 'downloaded_pdf.pdf';

        document.body.appendChild(link);

        link.click();

        window.URL.revokeObjectURL(pdfUrl);
        setpdfLoading("complete")

    } catch (error) {
        console.log(error);
        setpdfLoading("error")
    }
}

function FileExt(file) {
    return file.split('.')[1].toLowerCase()
}